.tap-link:hover .tap-img {
  opacity: 0.75;
}

.tap-link {
  .tap-action {
    display: none;
  }

  &:hover {
    .tap-action {
      display: flex;
    }
  }
}

.dropzone {
  min-width: 290px;
  box-sizing: border-box;
  text-align: center;
  padding: 20px;
  border: 3px dashed #009698;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: #e6f4f4;
  }

  p {
    margin: 8px 0;
    line-height: 24px;
  }

  .click-cta {
    color: #009698;
    text-decoration: underline;
    font-weight: 600;
  }
}
