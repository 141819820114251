.tap-container {
  .delete-tap {
    display: none;
  }

  &:hover {
    .delete-tap {
      display: block;
    }
  }
}