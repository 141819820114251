.tap-img:hover {
  opacity: 0.75;
}

.tap-link {
  .delete-tap {
    display: none;
  }

  &:hover {
    .delete-tap {
      display: block;
    }
  }
}
