/* glue: 0.13 hash: eab99e4aa4 */
        .sprite-images-alert-large,
.sprite-images-vT-logo,
.sprite-images-vT-logo-blue,
.sprite-images-palette,
.sprite-images-x,
.sprite-images-x-hover,
.sprite-images-alert-white,
.sprite-images-palette-orange,
.sprite-images-palette-gray,
.sprite-images-palette-blue,
.sprite-images-check-white,
.sprite-images-user,
.sprite-images-user-orange,
.sprite-images-user-blue,
.sprite-images-unordered-list,
.sprite-images-unordered-list-blue,
.sprite-images-trash,
.sprite-images-star,
.sprite-images-star-orange,
.sprite-images-star-blue,
.sprite-images-remove,
.sprite-images-remove-hover,
.sprite-images-remove-active,
.sprite-images-quote,
.sprite-images-quote-blue,
.sprite-images-ordered-list,
.sprite-images-ordered-list-blue,
.sprite-images-list,
.sprite-images-edit,
.sprite-images-document,
.sprite-images-document-orange,
.sprite-images-document-blue,
.sprite-images-carat,
.sprite-images-carat-white,
.sprite-images-blocks,
.sprite-images-add,
.sprite-images-add-small {
            background-image: url('images.png');
            background-repeat: no-repeat;
        }
        
        .sprite-images-alert-large {
            background-position: -2px -2px;
            width: 40px;
            height: 36px;
        }
        
        .sprite-images-vT-logo {
            background-position: -2px -42px;
            width: 26px;
            height: 36px;
        }
        
        .sprite-images-vT-logo-blue {
            background-position: -2px -82px;
            width: 26px;
            height: 36px;
        }
        
        .sprite-images-palette {
            background-position: -2px -122px;
            width: 20px;
            height: 19px;
        }
        
        .sprite-images-x {
            background-position: -2px -144px;
            width: 19px;
            height: 19px;
        }
        
        .sprite-images-x-hover {
            background-position: -2px -167px;
            width: 19px;
            height: 19px;
        }
        
        .sprite-images-alert-white {
            background-position: -2px -190px;
            width: 18px;
            height: 16px;
        }
        
        .sprite-images-palette-orange {
            background-position: -2px -210px;
            width: 18px;
            height: 16px;
        }
        
        .sprite-images-palette-gray {
            background-position: -2px -230px;
            width: 18px;
            height: 16px;
        }
        
        .sprite-images-palette-blue {
            background-position: -2px -250px;
            width: 18px;
            height: 16px;
        }
        
        .sprite-images-check-white {
            background-position: -2px -270px;
            width: 17px;
            height: 16px;
        }
        
        .sprite-images-user {
            background-position: -2px -290px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-user-orange {
            background-position: -2px -310px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-user-blue {
            background-position: -2px -330px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-unordered-list {
            background-position: -2px -350px;
            width: 16px;
            height: 15px;
        }
        
        .sprite-images-unordered-list-blue {
            background-position: -2px -369px;
            width: 16px;
            height: 15px;
        }
        
        .sprite-images-trash {
            background-position: -2px -387px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-star {
            background-position: -2px -407px;
            width: 14px;
            height: 16px;
        }
        
        .sprite-images-star-orange {
            background-position: -2px -427px;
            width: 14px;
            height: 16px;
        }
        
        .sprite-images-star-blue {
            background-position: -2px -447px;
            width: 14px;
            height: 16px;
        }
        
        .sprite-images-remove {
            background-position: -2px -467px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-remove-hover {
            background-position: -2px -487px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-remove-active {
            background-position: -2px -507px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-quote {
            background-position: -2px -527px;
            width: 16px;
            height: 7px;
        }
        
        .sprite-images-quote-blue {
            background-position: -2px -538px;
            width: 16px;
            height: 7px;
        }
        
        .sprite-images-ordered-list {
            background-position: -2px -548px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-ordered-list-blue {
            background-position: -2px -568px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-list {
            background-position: -2px -587px;
            width: 16px;
            height: 12px;
        }
        
        .sprite-images-edit {
            background-position: -2px -603px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-document {
            background-position: -2px -623px;
            width: 13px;
            height: 16px;
        }
        
        .sprite-images-document-orange {
            background-position: -2px -643px;
            width: 13px;
            height: 16px;
        }
        
        .sprite-images-document-blue {
            background-position: -2px -663px;
            width: 13px;
            height: 16px;
        }
        
        .sprite-images-carat {
            background-position: -2px -683px;
            width: 16px;
            height: 10px;
        }
        
        .sprite-images-carat-white {
            background-position: -2px -696px;
            width: 16px;
            height: 10px;
        }
        
        .sprite-images-blocks {
            background-position: -2px -710px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-add {
            background-position: -2px -730px;
            width: 16px;
            height: 16px;
        }
        
        .sprite-images-add-small {
            background-position: -2px -750px;
            width: 14px;
            height: 14px;
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
            .sprite-images-alert-large,
    .sprite-images-vT-logo,
    .sprite-images-vT-logo-blue,
    .sprite-images-palette,
    .sprite-images-x,
    .sprite-images-x-hover,
    .sprite-images-alert-white,
    .sprite-images-palette-orange,
    .sprite-images-palette-gray,
    .sprite-images-palette-blue,
    .sprite-images-check-white,
    .sprite-images-user,
    .sprite-images-user-orange,
    .sprite-images-user-blue,
    .sprite-images-unordered-list,
    .sprite-images-unordered-list-blue,
    .sprite-images-trash,
    .sprite-images-star,
    .sprite-images-star-orange,
    .sprite-images-star-blue,
    .sprite-images-remove,
    .sprite-images-remove-hover,
    .sprite-images-remove-active,
    .sprite-images-quote,
    .sprite-images-quote-blue,
    .sprite-images-ordered-list,
    .sprite-images-ordered-list-blue,
    .sprite-images-list,
    .sprite-images-edit,
    .sprite-images-document,
    .sprite-images-document-orange,
    .sprite-images-document-blue,
    .sprite-images-carat,
    .sprite-images-carat-white,
    .sprite-images-blocks,
    .sprite-images-add,
    .sprite-images-add-small {
                background-image: url('images.png');
                -webkit-background-size: 44px 766px;
                -moz-background-size: 44px 766px;
                background-size: 44px 766px;
            }
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 2.0), screen and (min--moz-device-pixel-ratio: 2.0), screen and (-o-min-device-pixel-ratio: 200/100), screen and (min-device-pixel-ratio: 2.0), screen and (min-resolution: 2.0dppx) {
            .sprite-images-alert-large,
    .sprite-images-vT-logo,
    .sprite-images-vT-logo-blue,
    .sprite-images-palette,
    .sprite-images-x,
    .sprite-images-x-hover,
    .sprite-images-alert-white,
    .sprite-images-palette-orange,
    .sprite-images-palette-gray,
    .sprite-images-palette-blue,
    .sprite-images-check-white,
    .sprite-images-user,
    .sprite-images-user-orange,
    .sprite-images-user-blue,
    .sprite-images-unordered-list,
    .sprite-images-unordered-list-blue,
    .sprite-images-trash,
    .sprite-images-star,
    .sprite-images-star-orange,
    .sprite-images-star-blue,
    .sprite-images-remove,
    .sprite-images-remove-hover,
    .sprite-images-remove-active,
    .sprite-images-quote,
    .sprite-images-quote-blue,
    .sprite-images-ordered-list,
    .sprite-images-ordered-list-blue,
    .sprite-images-list,
    .sprite-images-edit,
    .sprite-images-document,
    .sprite-images-document-orange,
    .sprite-images-document-blue,
    .sprite-images-carat,
    .sprite-images-carat-white,
    .sprite-images-blocks,
    .sprite-images-add,
    .sprite-images-add-small {
                background-image: url('images@2x.png');
                -webkit-background-size: 44px 766px;
                -moz-background-size: 44px 766px;
                background-size: 44px 766px;
            }
        }
        