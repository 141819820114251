$darkPrimaryColor: #169697;

svg.svg-container {
  display: block;
  margin: 0 auto;

  .viz-text {
    &.editable {
      pointer-events: all;
      cursor: pointer;

      &:hover tspan {
        fill: $darkPrimaryColor;
      }
    }
  }

  .editable-textpath {
    pointer-events: all;
    cursor: pointer;

    &:hover {
      fill: $darkPrimaryColor;
      textPath {
        fill: $darkPrimaryColor;
      }
    }
  }
}
