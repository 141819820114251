$darkPrimaryColor: #169697;

.edit-tapestry-container {
  flex-direction: row;

  .transcript-container {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%;
    box-sizing: border-box;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
}

.edit-tapestry {
  &.un-editable .tapestry-container {
    text.editable,
    .clickable-text,
    .editable-textpath {
      pointer-events: none !important;
    }
  }

  &.editing-phrases {
    text:not(.editable) {
      pointer-events: none !important;

      textPath {
        pointer-events: none !important;
      }
    }

    .svg-container {
      cursor: url("../assets/pointer-black-shadow.png") 12 9, pointer;
    }

    text.editable,
    .clickable-text,
    .editable-textpath {
      cursor: url("../assets/pointer-green-shadow.png") 12 9, pointer !important;
    }
  }

  &.searching-phrases {
    .svg-container {
      cursor: url("../assets/search-black-shadow.png") 20 18, pointer;
    }

    text.editable,
    .clickable-text,
    .editable-textpath {
      cursor: url("../assets/search-green-shadow.png") 20 18, pointer !important;
    }

    .symbol-phrase-container:hover textPath {
      fill: $darkPrimaryColor;
    }
  }

  .tapestry-action {
    &:hover p {
      color: $darkPrimaryColor;
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}

.phrase-option {
  $selectedColor: #c0e4e5;
  $selectionColor: lighten($selectedColor, 10%);

  &:hover {
    background-color: $selectionColor;
  }

  &.selected {
    background-color: $selectedColor;
  }
}

.edit-panel-option:hover {
  opacity: 0.75;
}

@media screen and (max-width: 1200px) {
  .edit-tapestry-container {
    flex-direction: column;

    .transcript-container {
      width: 100% !important;
      min-width: 100% !important;
      flex-basis: 100% !important;
      margin-left: 0 !important;
      margin-top: 10px;
      max-height: 600px;
    }
  }
}
