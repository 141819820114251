.nav-item {
  p {
    position: relative;
  }

  &:hover p::before,
  &.selected p::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 2px;
  }
}
